export default {
    self: {
        url: 'https://localhost:3000'
    },
    api: {
        url: 'https://api.YOURSITE.test/api',
        version: '1.0'
    },
    algolia: {
        appId: 'YQ6MNP6BE2',
        apiKey: '246d248a6ba2a970cd621e65cea285c5'
    }
};
