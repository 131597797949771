$(function($) {

    $('.background-slider').each(() => {
        let $slider = $(this),
            $container = $slider.find('.background-slider__slides'),
            slidesAmount = $slider.find('.background-slider__slide').length,
            currentSlide = 0,
            interval;

        $container.css('width', (100 * slidesAmount) + 'vw');
        interval = setInterval(() => {
            currentSlide++;

            if(currentSlide < slidesAmount) {
                $container.css('left', '-' + (100 * currentSlide) + 'vw');
            } else {
                clearInterval(interval);
            }

        }, 6000);
    });
});
