<template src="./internal-modal.html"></template>

<script>
    'use strict';

    import Axios from 'axios';

    export default {
        components: {},

        data() {
            return {
                open: false,
                content: ''
            };
        },

        props: {

        },

        computed: {

        },

        mounted() {
            this.$root.$on('openInternalModal', (url) => {
                this.openModal(url);
            });

            this.$root.$on('closeInternalModal', () => {
                this.closeModal(true);
            });
        },

        methods: {
            openModal: function(url) {
                // ajax call
                Axios.get(url).then((response) => {
                    let parser = new DOMParser(),
                        doc = parser.parseFromString(response.data, 'text/html');

                    this.content = doc.querySelector('.section-content').outerHTML;
                });

                // set open
                this.open = true;

                // body class
                document.body.classList.add('has-internal-modal');
            },

            closeModal: function(remainHistory) {
                this.open = false;

                this.content = '';
                document.body.classList.remove('has-internal-modal');

                if(remainHistory !== true) {
                    this.$router.go(-1);
                }
            }
        }
    };

</script>

