/*
 * General imports
 */
import Vue from 'vue';
import Router from 'vue-router';


/*
 * Import views
 */
import ProgrammeOverview from './patterns/pages/programme/overview.vue';


// Use router
Vue.use(Router);

// Setup routes
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/nl/programma',
            name: 'programme',
            component: ProgrammeOverview,
            props: route => ({
                internalModal: '',
                query: route.query.q,
                preselect: {
                    date: route.query.filter_date,
                    theme: route.query.filter_theme,
                    type: route.query.filter_type,
                    district: route.query.filter_district
                }
            })
        },
        {
            path: '/en/programme',
            name: 'programme-en',
            component: ProgrammeOverview,
            props: route => ({
                internalModal: '',
                query: route.query.q,
                preselect: {
                    date: route.query.filter_date,
                    theme: route.query.filter_theme,
                    type: route.query.filter_type,
                    district: route.query.filter_district
                }
            })
        },
        {
            path: '/:language?/production/:slug',
            name: 'production-detail',
            component: ProgrammeOverview,
            props: route => ({
                query: route.query.q,
                internalModal: route.path + '?ref=' + route.query.ref + '&lang=' + route.query.lang
            }),
            meta: {
                title: 'Programma-onderdeel'
            }
        }
    ]
});

export default router;
