// misc js
require('assets/js/overmorrow/overmorrow.js');
require('assets/js/stickyfill/stickyfill.js');
require('assets/js/play-button.js');
require('assets/js/at-button.js');
require('patterns/molecules/ekko-lightbox/ekko-lightbox.js');
require('patterns/molecules/background-slider/background-slider.js');

'use strict';

// polyfill
if (!Object.entries) {
    Object.entries = function( obj ){
        let ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array

        while (i--) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }

        return resArray;
    };
}

/**
 * General
 */
import 'es6-promise/auto';
import config from './config/config';
import bugsnag from '@bugsnag/js';

/**
 * Vue dependencies
 */
import Vue from 'vue';
import bugsnagVue from '@bugsnag/plugin-vue';

import Router from './router';
import Store from './store/store';
import Api from './api/api';

import VueAxios from 'vue-axios';
import VeeValidate from 'vee-validate';
import VueGtm from 'vue-gtm';
import InstantSearch from 'vue-instantsearch';
import * as VueGoogleMaps from 'vue2-google-maps';

// import {register as registerSW} from './precache-sw.js';
import {formValidationHandler} from 'helpers/formValidationHandler.js';

/**
 * Vue components
 */
 import internalModal from 'patterns/molecules/internal-modal/internal-modal.vue';
 import popupModal from 'patterns/molecules/popup-modal/popup-modal.vue';


/**
 * Vue enhancements
 */
Vue.use(VueAxios, Api);
Vue.use(VeeValidate);
Vue.use(InstantSearch);
Vue.use(VueGtm, {
    id: config.gtmId, // Your GTM ID
    enabled: process.env.NODE_ENV === 'production',
    debug: process.env.NODE_ENV !== 'production',
    vueRouter: Router
});
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAJgJ50cQZzh2QgWlqWFBUux_phTKBgYfw'
    }
});


/** Bugsnag ALWAYS KEEP FIRST **/
if (process.env.NODE_ENV === 'production') {
    const bugsnagClient = bugsnag({
        apiKey: config.bugsnagId,
        releaseStage: process.env.APP_ENV
    });
    bugsnagClient.use(bugsnagVue, Vue);
}
/** End bugsnag **/


/**
 * Vue initialization
 */
const app = new Vue({
    el: '#app',
    router: Router,
    store: Store,
    components: {internalModal, popupModal}
});
//registerSW(app);


Vue.config.errorHandler = function (error) {
    // handle error
    if (error.response) { // This will respond to axios / ajax errors
        // 422 errors eg. validation errors.
        if (error.response.status === 422) {
            formValidationHandler(error.response).bind(app);
        }
        app.$store.dispatch('toast/add', `ERROR [${error.response.status}] MESSAGE: ${error.response.data.error}`); // @TODO format your messages in a friendly manner.
    }
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
};
