var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:'card card--event ' + _vm.colClasses,attrs:{"data-objectid":_vm.item.objectID}},[_c('router-link',{staticClass:"card__link link link--clean",attrs:{"to":{
            path: _vm.getPermalinkPath(_vm.__('permalink')),
            query: {
                ref: _vm.getPermalinkRef(_vm.__('permalink')),
                lang: _vm.getPermalinkLang(_vm.__('permalink')),
                q: this.$route.query.q
            }
        }}},[_c('div',{staticClass:"card__image-container"},[_c('img',{staticClass:"card__image hidden-xs-down",attrs:{"src":_vm.item.images.card}}),_vm._v(" "),_c('img',{staticClass:"card__image card__image--mobile hidden-sm-up",attrs:{"src":_vm.item.images.card_mobile}})]),_vm._v(" "),_c('div',{staticClass:"card__content"},[_c('p',{staticClass:"card__meta"},[(_vm.item.taxonomies && _vm.item.taxonomies.type)?[_vm._v(_vm._s(_vm.item.taxonomies.type[0])+" ·")]:_vm._e(),_vm._v(" "),(_vm.item.date)?[_vm._v(_vm._s(_vm.formatDate(_vm.item.date)))]:_vm._e(),_vm._v(" "),(_vm.item.time_start)?[_vm._v(_vm._s(_vm.item.time_start))]:_vm._e(),_vm._v(" "),(_vm.item.time_end)?[_vm._v("- "+_vm._s(_vm.item.time_end)+" ·")]:_vm._e(),_vm._v(" "),(_vm.item.venue)?[_vm._v(_vm._s(_vm.removeVenueAddress(_vm.item.venue)))]:_vm._e()],2),_vm._v(" "),_c('h2',{staticClass:"card__title"},[(_vm.language === 'en' && _vm.algolia)?[_c('ais-highlight',{attrs:{"result":_vm.item,"attribute-name":"post_title_en"}})]:(_vm.algolia)?[_c('ais-highlight',{attrs:{"result":_vm.item,"attribute-name":"post_title"}})]:[_vm._v("\n                    "+_vm._s(_vm.__('post_title'))+"\n                ")]],2),_vm._v(" "),_c('small',{staticClass:"card__description"},[_vm._v(_vm._s(_vm.__('excerpt')))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }