<template src="./aiscustom-refinement-list.html"></template>

<script>
    import { Component, FACET_OR, FACET_AND } from 'vue-instantsearch';

    export default {
        mixins: [Component],
        props: {
            attributeName: {
                type: String,
                required: true
            },
            preselect: {
                type: String,
                default: ''
            },
            operator: {
                type: String,
                default: FACET_OR,
                validator(rawValue) {
                    const value = rawValue.toLowerCase();
                    return value === FACET_OR || value === FACET_AND;
                }
            },
            limit: {
                type: Number,
                default: 20
            },
            sortBy: {
                default() {
                    return ['isRefined:desc', 'count:desc', 'name:asc'];
                }
            },
            // set this to overwrite the possible facet values (i.e. for persistent filter options)
            values: {
                type: Array,
                required: false
            },
            // optional sub labels, make array to match values
            sublabels: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                blockClassName: 'ais-refinement-list'
            };
        },
        created() {
            let /*date = new Date(),
                month = 0,
                today,*/
                changed = false;

            // add facet
            this.searchStore.addFacet(this.attributeName, this.operator);

            // pre select
            if(this.preselect !== '') {

                // loop preselects
                this.preselect.split(',').forEach((value) => {
                    // toggle preselect
                    this.searchStore.addFacetRefinement(this.attributeName, value);
                });

                changed = true;
            }

            /*// pre-select current date
            else if(this.attributeName === 'date') {
                month = date.getMonth() + 1;
                if(month < 10) {
                    month = '0' + month;
                }
                today = date.getFullYear() + '-' + month + '-' + date.getDate();

                // loop values, see if today is an option
                this.values.forEach((value) => {
                    if(value === today) {
                        changed = true;
                        // refine on date
                        this.searchStore.addFacetRefinement(this.attributeName, today);
                    }
                });
            }*/

            // update parent
            if(changed) {
                setTimeout(()=>{
                    this.$emit('facetRefinementsChanged', this.attributeName, this.facetValues);
                }, 500);
            }
        },
        destroyed() {
            this.searchStore.stop();
            this.searchStore.removeFacet(this.attributeName);
            this.searchStore.start();
        },
        computed: {
            facetValues() {
                let overwrittenValues = this.values,
                    completeValues = [],
                    relevantValues = [],
                    relevantValueLookup = {};

                // get the currently relevant values from algolia
                relevantValues = this.searchStore.getFacetValues(
                    this.attributeName,
                    this.sortBy,
                    this.limit
                );

                // no overwritten values? just return relevant values
                if (!overwrittenValues) {
                    return relevantValues;
                }

                // transfer relevant values for quick lookup object
                relevantValues.forEach(value => {
                    relevantValueLookup[value.name] = value;
                });

                // loop overwritten values
                overwrittenValues.forEach(name => {
                    let value;

                    // is the value relevant? copy relevant object, set relevant to true
                    if (typeof relevantValueLookup[name] !== 'undefined') {
                        value = relevantValueLookup[name];
                        value.isRelevant = 'true';
                        completeValues.push(value);
                    }
                    // irrelevant? count 0, isRefined false, relevant false
                    else {
                        value = {
                            name: name,
                            count: 0,
                            isRefined: false,
                            isRelevant: false
                        };

                        completeValues.push(value);
                    }
                });



                return completeValues;
            },
            show() {
                return this.facetValues.length > 0;
            }
        },
        methods: {
            toggleRefinement(value) {
                this.searchStore.toggleFacetRefinement(this.attributeName, value.name);

                return this.$emit('facetRefinementsChanged', this.attributeName, this.facetValues);
            },

            slugify(text) {
                return text.toString().toLowerCase()
                    .replace(/\s+/g, '-')           // Replace spaces with -
                    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                    .replace(/^-+/, '')             // Trim - from start of text
                    .replace(/-+$/, '');            // Trim - from end of text
            },

            formatFacetName(name) {
                let event, weekday, date, split, locale ='nl-NL';

                if(this.attributeName !== 'date') {
                    return name;
                }

                if (window.current_language === 'en') {
                    locale = 'en-US';
                }

                // date format
                split = name.split('-');
                event = new Date(Date.UTC(split[0], parseInt(split[1], 10) - 1, split[2]));

                weekday = event.toLocaleDateString(locale, {
                    weekday: 'long'
                });

                date = event.toLocaleDateString(locale, {
                    month: 'long',
                    day: 'numeric'
                });

                return weekday + ' <span class="facet__date">' + date + '</span>';
            }
        },
        watch: {
            operator() {
                this.searchStore.addFacet(this.attributeName, this.operator);
            }
        }
    };
</script>
