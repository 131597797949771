var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"programme-map"},[_c('GmapMap',{ref:"programmeMap",attrs:{"zoom":_vm.mapZoom,"center":_vm.mapPosition,"map-type-id":"terrain","options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            styles: _vm.mapsStyle
        }},on:{"click":function($event){_vm.infoWindowOpen=false}}},[_vm._l((_vm.locations),function(data,venue){return _c('GmapMarker',{key:venue,attrs:{"position":data.latlng,"clickable":true,"draggable":false,"label":_vm.getMarkerLabel(data),"icon":_vm.getMarkerIcon(data),"optimized":"false"},on:{"click":function($event){return _vm.toggleInfoWindow(data)}}})}),_vm._v(" "),_c('GmapInfoWindow',{staticClass:"infowindow",attrs:{"options":_vm.infoWindowOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWindowOpen !== false},on:{"closeclick":function($event){_vm.infoWindowOpen=false}}},[_c('div',{staticClass:"infowindow"},[_c('ul',{staticClass:"infowindow__list"},_vm._l((_vm.infoWindowEvents),function(event){return _c('li',{staticClass:"infowindow__item"},[_c('router-link',{staticClass:"infowindow__link",attrs:{"to":{
                            path: _vm.getPermalinkPath(_vm.__(event, 'permalink')),
                            query: {
                                ref: _vm.getPermalinkRef(_vm.__(event, 'permalink')),
                                lang: _vm.getPermalinkLang(_vm.__(event, 'permalink'))
                            }
                        }}},[_vm._v("\n                            "+_vm._s(_vm.__(event, 'post_title'))+"\n\n                            "),_c('svg',{staticClass:"infowindow__caret",attrs:{"width":"10px","height":"6px","viewBox":"0 0 10 6","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('g',{attrs:{"id":"Symbols","stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd","stroke-linecap":"square"}},[_c('g',{attrs:{"id":"Programma-navigatie-closed","transform":"translate(-180.000000, -30.000000)","stroke":"#FFFFFF"}},[_c('g',{attrs:{"id":"Group-2","transform":"translate(180.000000, 30.000000)"}},[_c('path',{attrs:{"d":"M0.5,0.5 L5,5.5","id":"Line"}}),_vm._v(" "),_c('path',{attrs:{"d":"M9.5,0.5 L5,5.5","id":"Line-2"}})])])])])])],1)}),0)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }