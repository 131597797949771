<template src="./facet-navitem.html"></template>

<script>
    'use strict';

    export default {

        props: {
            facet: {
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            getLabel() {
                let split, event, name, locale = 'nl-NL';

                // no active refinement: show 'all' label
                if (this.facet.activeValues.length === 0) {
                    if(window.current_language === 'en') {
                        return 'All ' + this.facet.labelEn;
                    }
                    return 'Alle ' + this.facet.label;
                }

                // one active refinement: show refinement value
                else if(this.facet.activeValues.length === 1) {
                    // get name
                    name = this.facet.activeValues[0];

                    // check if this is a date field
                    if (this.facet.name !== 'date') {
                        return name;
                    }

                    // date formatting
                    split = name.split('-');
                    event = new Date(Date.UTC(split[0], parseInt(split[1], 10) - 1, split[2]));

                    if (window.current_language === 'en') {
                        locale = 'en-US';
                    }

                    return event.toLocaleDateString(locale, {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    });
                }
                // more than one active refinement: show amount of refinements
                else {
                    if(window.current_language === 'en') {
                        return this.facet.activeValues.length + ' ' + this.facet.labelEn;
                    }
                    return this.facet.activeValues.length + ' ' + this.facet.label;
                }
            }
        }
    };

</script>
