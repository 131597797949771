'use strict';

import Axios from 'axios';
import config from '../config/config.js';

const axios = Axios.create({
    baseURL: config.api.url + '/v' + config.api.version + '/',
    timeout: 5000
});

export default axios;
export function init(/*VueInstance*/) {
    // This code will add a bearer token to every request sent via this api instance.
    /*let token = VueInstance.$store.getters['auth/accessToken'];
    if (token) {
        VueInstance.axios.defaults.headers.common = Object.assign({}, VueInstance.axios.defaults.headers.common, {
            Authorization: `Bearer ${token}`
        });
    }*/
}
