export function formValidationHandler(response) {
    if (response.data.errors) {
        for (const fieldName in response.data.errors) {
            if (response.data.errors.hasOwnProperty(fieldName)) {
                const messages = response.data.errors[fieldName];
                for (const message of messages) {
                    this.$store.dispatch('toast/add', message);
                }
            }
        }
    }
    else if (response.data.message) {
        this.$store.dispatch('toast/add', response.data.message);
    }
}