const env = process.env.APP_ENV || 'development';

const config = {
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY H:i',
    bugsnagId: 'aa37d9993ea08919aa4db820600ea493',
    gtmId: 'GTM-WD5763B',
    assetsPath: '/wp-content/themes/wemakethecity/dist'
};

const localizedConfigs = {
    production: require('./production').default,
    staging: require('./staging').default,
    development: require('./development').default
};

const localizedConfig = localizedConfigs[env];

export default Object.assign(config, localizedConfig);
