import Cookies from 'js-cookie';

$(document).ready(function() {
    $(document).on('click', '[data-at-btn]', function() {
        const $button = $(this),
              action = $button.attr('data-at-btn');

        // set cookie
        Cookies.set('at-action', action, {
            expires: 1
        });
    });
});
