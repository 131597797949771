export default {
    self: {
        url: 'https://2019.wemakethecity.ceres.digitalnatives.nl'
    },
    api: {
        url: 'https://api.YOURSITE.mars.digitalnatives.nl/api',
        version: '1.0'
    },
    algolia: {
        appId: 'YQ6MNP6BE2',
        apiKey: '246d248a6ba2a970cd621e65cea285c5'
    }
};
