<template src="./popup-modal.html"></template>

<script>
    'use strict';
    
    import Cookie from 'js-cookie';

    export default {
        components: {},

        data() {
            return {
                open: false,
                poster: 'https://' + window.location.host + '/wp-content/themes/wemakethecity/video/video.jpg',
                videoPathMp4: 'https://' + window.location.host + '/wp-content/themes/wemakethecity/video//video.mp4',
                videoPathOgg: 'https://' + window.location.host + '/wp-content/themes/wemakethecity/video//video.ogg',
                videoPathWebm: 'https://' + window.location.host + '/wp-content/themes/wemakethecity/video/video.webm'

            };
        },

        mounted: function () {
            if (!Cookie.get('pop-up-modal')) {
                this.open = true;
                Cookie.set('pop-up-modal', true, { expires: 365 });
            }
            if(this.open === true) {
                document.body.classList.add('open-modal-pop-op');
            }
        },
        methods: {
            openModal: function() {
                // set open
                this.open = true;

                // body class
                document.body.classList.add('open-modal-pop-op');
            },

            closeModal: function() {
                this.open = false;
                document.body.classList.remove('open-modal-pop-op');

            }
        }
    };

</script>
