<template src="./overview.html"></template>

<script>
    'use strict';

    import config from 'config/config';
    import card from 'patterns/molecules/card/card.vue';
    import programmeMap from 'patterns/organisms/programme-map/programme-map.vue';
    import aiscustomRefinementList from 'patterns/molecules/aiscustom-refinement-list/aiscustom-refinement-list.vue';
    import facetNavitem from 'patterns/atoms/facet-navitem/facet-navitem.vue';
    import { createFromAlgoliaCredentials } from 'vue-instantsearch';
    import {removeParentheses} from 'helpers/removeParentheses.js';
    const searchStore = createFromAlgoliaCredentials(
        config.algolia.appId,
        config.algolia.apiKey
    );
    searchStore.indexName = 'instant_search';

    export default {
        components: {card, aiscustomRefinementList, facetNavitem, programmeMap},
        props: {
            query: {
                type: String,
                default: ''
            },
            internalModal: {
                type: String,
                default: ''
            },
            preselect: {
                type: Object,
                default: () => {
                    return {
                        date: '',
                        theme: '',
                        type: '',
                        district: ''
                    };
                }
            }
        },
        data() {
            return {
                searchStore,
                config,
                filterTimer: null,
                highlightedResults: {},
                filterVisible: false,
                activeFacet: '',
                blockClassName: 'overview',
                mapVisible: (window.innerWidth >= 768),
                language: window.current_language,
                locations: {},
                facets: {
                    date: {
                        name: 'date',
                        label: 'Dagen',
                        labelEn: 'Days',
                        attribute: 'date',
                        values: [
                            '2019-06-17',
                            '2019-06-18',
                            '2019-06-19',
                            '2019-06-20',
                            '2019-06-21',
                            '2019-06-22',
                            '2019-06-23'
                        ],
                        activeValues: [],
                        sublabels: [
                            'Festivalopening',
                            'Conferenties & Gebiedsmeet-ups',
                            'Conferenties & Gebiedsmeet-ups',
                            'Conferenties & Gebiedsmeet-ups',
                            'Conferenties & Gebiedsmeet-ups',
                            'Festivalweekend',
                            'Festivalweekend'
                        ],
                        sublabelsEn: [
                            'Festival Opening',
                            'Conferences & Area Meet-ups',
                            'Conferences & Area Meet-ups',
                            'Conferences & Area Meet-ups',
                            'Conferences & Area Meet-ups',
                            'Festival Weekend',
                            'Festival Weekend'
                        ]
                    },
                    theme: {
                        name: 'theme',
                        label: 'Thema\'s',
                        labelEn: 'Themes',
                        attribute: 'taxonomies.theme',
                        values: [
                            'Circular',
                            'Climate proof',
                            'Connected',
                            'Healthy',
                            'Inclusive',
                            'Liveable',
                            'Safe',
                            'Skilled',
                            'Smart',
                            'Sustainable',
                            'Thriving',
                            'Vibrant'
                        ],
                        activeValues: []
                    },
                    type: {
                        name: 'type',
                        label: 'Soorten',
                        labelEn: 'Types',
                        attribute: 'taxonomies.type',
                        values: [
                            'Urban Conference',
                            'Urban Talk',
                            'Urban Movie',
                            'Urban Expo',
                            'Metropolitan Conference',
                            'Workshop',
                            'Hackathon',
                            'Expeditie',
                            'Special',
                            'Open Huis',
                            'Kids'
                        ],
                        activeValues: []
                    },
                    district: {
                        name: 'district',
                        label: 'Gebieden',
                        labelEn: 'Areas',
                        attribute: 'taxonomies.district',
                        values: [
                            'Amsterdam-Noord',
                            'Amsterdam-Oost',
                            'Amsterdam-Zuid',
                            'Amsterdam-Zuidoost',
                            'Amsterdam-West',
                            'Amsterdam-Nieuw West', //hotfix voor nu om de filter te laten werken
                            'Amsterdam-Westpoort',
                            'Amsterdam-Centrum',
                            'Amsterdam-Zuidas',
                            'Amsterdam-Marineterrein',
                            'Almere',
                            'Amstelveen',
                            'Haarlem',
                            'Haarlemmermeer',
                            'Zaanstreek',
                            'Regio'
                        ],
                        activeValues: []
                    }
                }
            };
        },
        mounted() {

            if(this.internalModal !== '') {
                this.$root.$emit('openInternalModal', this.internalModal);
            } else {
                this.$root.$emit('closeInternalModal');
            }

        },
        watch: {
            'searchStore.query'(value) {
                let query = this.getQueryString();
                if(this.$route.name !== 'programme' && this.$route.name !== 'programme-en') {
                    return;
                }

                query.q = value;
                this.$router.push({
                    query: query
                });
            },
            'searchStore._results'(results) {
                this.setLocations(results);
                this.scrollToTop();
            },
            'internalModal'(url) {
                if(url !== '') {
                    this.$root.$emit('openInternalModal', url);
                } else {
                    this.$root.$emit('closeInternalModal');
                }
            }
        },
        computed: {
            queryParameters() {
                let params = {
                    query: this.query,
                    filters: 'NOT taxonomies.type:Break-outsessie'
                };

                if(this.language === 'en') {
                    params.filters += ' AND taxonomies.language:English';
                }
                return params;
            },

            toggleMapText() {
                if(this.language === 'en') {
                    return 'Show map';
                }
                return 'Toon kaart';
            },

            containerSize() {
                return this.mapVisible ? 7 : 12;
            },

            themeIntro() {
                let theme = '';

                // only display if we have one theme to show
                if(this.facets.theme.activeValues.length !== 1) {
                    return false;
                }

                // get theme
                theme = window.globalThemes[this.facets.theme.activeValues[0]];
                if(typeof theme === 'undefined') {
                    return false;
                }

                return `<img class="overview__theme-intro__logo" alt="${theme.name}" src="${this.config.assetsPath}/img/theme-${theme.slug}-large.svg"><p class="overview__theme-intro__description">${theme.description}</p>`;
            }
        },

        methods: {
            scrollToTop() {
                const currentScroll = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0),
                    navOffset = 205;

                if(currentScroll > navOffset) {
                    window.scroll(0, navOffset);
                }
            },

            // toggle filter visible on mobile
            toggleFilterVisible() {
                // already active: deactivate
                if (this.filterVisible === true) {
                    this.filterVisible = false;
                } else {
                    this.filterVisible = true;
                }
            },

            // toggle the active facet bar
            toggleActive(name) {
                // already active: deactivate
                if (this.activeFacet === name) {
                    this.activeFacet = '';
                } else {
                    this.activeFacet = name;
                }

                return this.activeFacet;
            },

            // toggle the active facet bar
            toggleMapVisible() {
                // already active: deactivate
                if (this.mapVisible) {
                    this.mapVisible = false;
                } else {
                    this.mapVisible = true;
                }

                this.highlightedResults = {};

                return this.mapVisible;
            },

            planFilterHide() {
                this.filterTimer = setTimeout(() => {
                    this.activeFacet = '';
                }, 700);
            },

            cancelFilterHide() {
                clearTimeout(this.filterTimer);
            },

            // event for when facet refinements have changed
            facetRefinementsChanged(attribute, refinements) {
                let routerOptions;

                // sanitize attribute
                attribute = attribute.replace('taxonomies.', '');

                // finds attribute in facets
                this.facets[attribute].activeValues = [];

                // push to router
                routerOptions = {
                    query: this.getQueryString()
                };

                // reset this refinement's filter variable
                routerOptions.query['filter_' + attribute] = '';

                // find refinements
                refinements.forEach((refinement) => {
                    if (refinement.isRefined) {
                        this.facets[attribute].activeValues.push(refinement.name);

                        if (routerOptions.query['filter_' + attribute] === '') {
                            routerOptions.query['filter_' + attribute] = refinement.name;
                        } else {
                            routerOptions.query['filter_' + attribute] += ',' + refinement.name;
                        }
                    }
                });

                // push to router
                this.$router.push(routerOptions);

                // return
                return this.facets[attribute].activeValues;
            },

            getQueryString() {
                let filterName,
                    filter,
                    query = {};

                // get search query
                if (typeof this.$route.query.q !== 'undefined') {
                    query.q = this.$route.query.q;
                }

                // get filters
                for (filter in this.facets) {
                    if (filter) {
                        filterName = 'filter_' + filter;
                        if (typeof this.$route.query[filterName] !== 'undefined') {
                            query[filterName] = this.$route.query[filterName];
                        }
                    }
                }

                // retuyrn querystring
                return query;
            },

            setLocations(results) {
                this.locations = {};
                results.forEach((result) => {
                    if(result.latitude && result.longitude) {
                        // create location entry if it doesn't exist
                        if(typeof this.locations[result.venue] === 'undefined') {
                            this.locations[result.venue] = {
                                events: [],
                                name: removeParentheses(result.venue),
                                address: result.venue,
                                latlng: {
                                    lat: result.latitude,
                                    lng: result.longitude
                                }
                            };
                        }

                        // add event to location content
                        this.locations[result.venue].events.push(result);
                    }
                });
            },

            // event for when facet refinements have changed
            resultsHighlighted(results) {
                this.highlightedResults = {};

                results.forEach((result) => {
                    this.highlightedResults[result.post_id] = result.post_id;
                });
            },

            isResultHighlighted(postId)
            {
                const highlights = this.highlightedResults;

                // no highlighted results found? highlight
                if(Object.keys(highlights).length === 0 && highlights.constructor === Object) {
                    return true;
                }

                // match highlighted results against post id
                if (typeof highlights[postId] !== 'undefined') {
                    return true;
                }

                // highlighted results found, but no match? don't highlight
                return false;
            },

            getFacetLabel(facet) {
                if(this.language === 'en') {
                    return facet.labelEn;
                }

                return facet.label;
            }
        }
    };

</script>
