<template src="./card.html"></template>

<script>
    'use strict';

    import config from 'config/config';
    import {removeParentheses} from 'helpers/removeParentheses.js';

    export default {
        components: {},
        data() {
            return {
                config,
                language: window.current_language
            };
        },
        mounted() {

        },
        props: {
            extraClass: String,
            type: {
                type: String,
                required: false,
                default: 'event'
            },
            item: {
                type: Object,
                required: true
            },
            algolia: {
                type: Boolean,
                default: false
            },
            containerSize: {
                type: Number,
                default: 12
            }
        },
        computed: {
            colClasses() {
                if(this.containerSize < 11) {
                    return 'col-sm-6';
                }
                return 'col-sm-6 col-md-4 col-lg-3';
            }
        },
        methods: {
            removeVenueAddress: function (text) {

                return removeParentheses(text);
            },

            formatDate: function (date) {
                let split = date.split('-'),
                    event = new Date(Date.UTC(split[0], parseInt(split[1], 10) - 1, split[2])),
                    locale = 'nl-NL';

                if (window.current_language === 'en') {
                    locale = 'en-US';
                }

                return event.toLocaleDateString(locale, {
                    month: 'long',
                    day: 'numeric'
                });
            },

            getPermalinkPath: function(url) {
                let path = url.indexOf('?') > 0 ? url.substr(0, url.indexOf('?')) : url;

                return path.replace(this.config.self.url, '');
            },

            getPermalinkRef: function(url) {
                return this.getParameterByName('ref', url);

            },

            getPermalinkLang: function(url) {
                return this.getParameterByName('lang', url);
            },

            __: function(property) {
                let propertyEn = property + '_en';

                if(window.current_language === 'en' && typeof this.item[propertyEn] !== 'undefined') {
                    return this.item[propertyEn];
                }

                return this.item[property];
            },

            getParameterByName: function(name, url) {
                name = name.replace(/[\[\]]/g, '\\$&');

                let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);

                if (!results) {
                    return null;
                }

                if (!results[2]) {
                    return '';
                }

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        }
    };

</script>

