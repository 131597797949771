export default {
    self: {
        url: 'https://wemakethe.city'
    },
    api: {
        url: 'https://api.YOURSITE.net/api',
        version: '1.0'
    },
    algolia: {
        appId: 'O8CR3LNRK2',
        apiKey: '3563307c17747fb8f2ce109c2af1a8da'
    }
};
