$(document).ready(function() {
    var currentHref,
        result,
        textValue,
        value,
        readable,
        item,
        str,
        map,
        videoURL,
        star,
        numStars,
        ww,
        wh,
        x,
        randomDelay,
        $grid,
        iso;

    if ( jQuery( '.lang-item' ).length ) {
        jQuery('.row-partners').on('click', function(){
            window.location.href = '/partners';
            return false;
        });
    }

    if ( jQuery( '.lang-item' ).length ) {
        currentHref = jQuery('.lang-item a').attr('href');
        jQuery('.lang-item a').attr('href', currentHref + decodeURIComponent(window.location.search));
    }


    if ( $( '.row-3' ).length ) {
        setInterval(function(){
            $('.row-3').toggle();
        }, 4000);
    }

    if ( $( '.row-4' ).length ) {
        setInterval(function(){
            item = $('.row-4:visible');

            if( item.next('.row-4:hidden').length ) {
                item.hide().next('.row-4:hidden').show();
            } else {
                item.hide();
                $('.row-4:first').show();
            }
        }, 4000);
    }

    if ( $( '.trans' ).length ) {
        setInterval(function(){
            item = $('.trans:visible');

            if( item.next('.trans:hidden').length ) {
                item.hide().next('.trans:hidden').show();
            } else {
                item.hide();
                $('.trans:first').show();
            }
        }, 4000);
    }


    $('.video a').click(function() {
        videoURL = $('.embed-responsive-item').prop('src');
        videoURL += '&autoplay=1';


        $('html, body').animate({
            scrollTop: $('.video').offset().top
        }, 700);


        if ($('.video').hasClass('animated')){
            $('.section-video').animate({
                height: 700
            }, 200);

            $('.embed-responsive-item').prop('src',videoURL);
            $('.video').removeClass('animated');
        } else {
            $('.section-video').animate({
                height: 160
            }, 200);

            $('.video').addClass('animated');
        }

        return false;
    });




    star = '<span class="star"></span>';
    numStars = 100;
    for (x = 1; x <= numStars; x++) {
        randomDelay = Math.random()*100;
        $(star).css('animation-delay', randomDelay + 's').appendTo('body');
    }

    ww = $(window).width();
    wh = $(window).height();
    $('.star').each(function() {
        var posx = Math.round(Math.random() * ww) - 20;
        var posy = Math.round(Math.random() * wh) - 20;
        $(this).css('top', posy + 'px').css('left', posx + 'px');
    });


    $grid = $('.journal-grid').isotope({
        percentPosition: true,
        itemSelector: '.grid-item',
        masonry: {
            columnWidth: '.grid-item'
        }
    });

    $grid.imagesLoaded().progress( function() {
        $grid.isotope('layout');
    });

    iso = $grid.data('isotope');


    $('.single-post .section-content').after().click(function(e) {
        if(e.pageY < 650){
            window.history.back();
        }
    });

    $('.nav-filter').on( 'click', 'a', function() {
        var filterValue = $(this).attr('data-filter');
        $grid.isotope({ filter: filterValue });
        $('.nav-filter a').removeClass('active');
        $(this).addClass('active');
        $('.speakers-list__item').addClass('hidden');
        $('.speakers-list__item' + filterValue).removeClass('hidden');

        return false;
    });

    $grid.infiniteScroll({
        path : '.pagination a',
        append: '.grid-item',
        outlayer: iso
    });


    $('.navtext').hover(function(){
        $('.tab-nav').css('left', '100px');
    }, function(){
        $('.tab-nav').css('left', '90px');
    });

    $('.section-latest-news .item-news').each(function(){
        var imageHeight = $('img', this).height() - 85;
        $(this).css('top', '-' + imageHeight + 'px');
    });

    $('.section-latest-news .item-news').hover(function(){
        $(this).animate({'marginTop': '-50px'}, 150 );
    }, function(){
        $(this).animate({'marginTop': '0px'}, 150 );
    });

});
