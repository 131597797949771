'use strict';

import Vue from 'vue';

// The initial state
const State = {
    messages: []
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    messages: ({messages}) => messages
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    add({commit}, message) {
        return commit('addMessage', message);
    },
    remove({commit}) {
        return commit('removeOldest');
    }
};

// Mutations change the actual data in the state
const Mutations = {
    clearAll(state) {
        Vue.set(state.messages, []);
    },
    addMessage(state, message) {
        // Check duplicates
        if (state.messages.indexOf(message) === -1) {
            state.messages.push(message);
        }
    },
    removeOldest(state) {
        state.messages.shift();
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
