'use strict';

// Main dependencies
import Vue from 'vue';
import Vuex from 'vuex';

// VueX modules
import toast from 'store/modules/toast.js';
import popup from 'store/modules/popup.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        toast, popup
    },

    strict: process.env.NODE_ENV !== 'production'
});

export default store;
