<template src="./programme-map.html"></template>

<script>
    'use strict';

    import config from 'config/config';
    import {gmapApi} from 'vue2-google-maps';

    export default {
        components: {},
        props: {
            locations: {
                type: Object
            }
        },
        data() {
            return {
                infoWindowEvents: [],
                infoWindowPos: null,
                infoWindowOpen: false,
                infoWindowOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                mapZoom: 11,
                mapPosition: {lat:52.354775, lng:4.84543},
                mapsStyle: [
                    {
                        stylers: [

                            { saturation: 0  },
                            { lightness: 33 },
                            { gamma: 0.5 }
                        ]
                    },{ 'featureType': 'transit.station.airport', 'elementType': 'geometry.fill', 'stylers': [ { 'color': '#4c7b92' } ] },{ 'featureType': 'transit.line', 'stylers': [ { 'color': '#4c7b92' } ] },{ 'elementType': 'labels.icon', 'stylers': [ { 'visibility': 'off' } ] },{ 'elementType': 'labels.text', 'stylers': [ { 'color': '#feffff' } ] },{ 'elementType': 'labels.text.stroke', 'stylers': [ { 'visibility': 'off' } ] },{
                        'featureType': 'landscape.natural',
                        'elementType': 'geometry.fill',
                        'stylers': [
                            {
                                'color': '#252746'
                            }
                        ]
                    },{
                        'featureType': 'landscape.man_made',
                        'elementType': 'geometry.fill',
                        'stylers': [
                            {
                                'color': '#252746'
                            }
                        ]
                    },         {
                        featureType: 'poi.business',
                        elementType: 'labels.icon',
                        stylers: [
                            { visibility: 'off' },
                            { hue: '#fff700' },
                            { lightness: -15 },
                            { saturation: 99 }
                        ]
                    }, {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [
                            { color: '#81D3DF' },
                            { lightness: 15 }
                        ]
                    }, {
                        featureType: 'transit.station.rail',
                        elementType: 'labels.text.stroke',
                        stylers: [
                            { visibility: 'off' },
                            { color: '#FF6666' }
                        ]
                    }, {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [
                            { visibility: 'off' },
                            { lightness: -35 }
                        ]
                    }, {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [
                            { color: '#4c7b92' }
                        ]
                    }, {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [
                            { color: '#4c7b92' }
                        ]
                    }, {
                        featureType: 'road.local',
                        elementType: 'geometry.fill',
                        stylers: [
                            { color: '#4c7b92' }
                        ]
                    }, {
                        featureType: 'road.local',
                        elementType: 'geometry.stroke',
                        stylers: [
                            { color: '#4c7b92' }
                        ]
                    }, {
                        featureType: 'road.arterial',
                        elementType: 'geometry.fill',
                        stylers: [
                            { color: '#4c7b92' }
                        ]
                    }, {
                        featureType: 'road.arterial',
                        elementType: 'geometry.stroke',
                        stylers: [
                            { color: '#4c7b92' }
                        ]
                    }
                ]
            };
        },
        watch: {
            locations() {
                this.resetMap();
            }
        },
        mounted() {
            this.$refs.programmeMap.$on('click', () => {
                this.infoWindowOpen = false;
                this.$emit('eventsHighlighted', []);
            });
        },
        computed: {
            google: gmapApi
        },
        methods: {

            // reset the map to ideal bounds and zoom
            resetMap()
            {
                let map = this.$refs.programmeMap.$mapObject;
                map.panTo(this.mapPosition);
                map.setZoom(this.mapZoom);
            },

            // show infow window
            toggleInfoWindow(location)
            {
                if(this.infoWindowOpen === location.address) {
                    this.infoWindowOpen = false;
                    this.$emit('eventsHighlighted', []);
                } else {
                    this.infoWindowEvents = location.events;
                    this.infoWindowPos = location.latlng;
                    this.infoWindowOpen = location.address;
                    this.$emit('eventsHighlighted', location.events);
                }
            },

            // get marker label showing amount of events
            getMarkerLabel(location)
            {
                let amount = location.events.length;
                if(amount < 2) {
                    return '';
                }

                return {
                    text: amount.toString(),
                    color: 'white',
                    fontFamily: 'Sentinel-Book',
                    fontSize: '16px'
                };
            },

            getMarkerIcon(location)
            {
                let amount = location.events.length,
                    variation = amount > 1 ? 'multiple' : 'single',
                    size = amount > 1 ? 37 : 22;

                return {
                    scaledSize: new this.google.maps.Size(size, size),
                    url: config.self.url + config.assetsPath + '/img/marker-' + variation + '.svg'
                };
            },

            getPermalinkPath: function(url) {
                let path = url.indexOf('?') > 0 ? url.substr(0, url.indexOf('?')) : url;

                return path.replace(config.self.url, '');
            },

            getPermalinkRef: function(url) {
                return this.getParameterByName('ref', url);
            },

            getPermalinkLang: function(url) {
                return this.getParameterByName('lang', url);
            },

            __: function(event, property) {
                let propertyEn = property + '_en';

                if(window.current_language === 'en' && typeof event[propertyEn] !== 'undefined') {
                    return event[propertyEn];
                }

                return event[property];
            },

            getParameterByName: function(name, url) {
                name = name.replace(/[\[\]]/g, '\\$&');

                let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);

                if (!results) {
                    return null;
                }

                if (!results[2]) {
                    return '';
                }

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        }
    };
</script>
